import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { Styles } from '@/other/UtilTypes';
import OpenChat from './OpenChat/OpenChat';
import { ChatContextProvider } from './ChatContext';
import ChatSidebar from './OpenChat/ChatSidebar';
import { useSearchParams } from 'react-router-dom';
import ContactActions, {
  ContactFilter as ChatFilterType
} from '@/data/DataServer/Contact';
import { useLinkedInAccount } from '@/data/LinkedIn/Account';
import log from 'electron-log';
import ChatFilter from './ChatFilter';
import { language } from '@/index';
import { OptionalTooltip, SearchField } from 'ui-utils';
import { AbstractChat } from '@/data/Classes/Chat/AbstractChat';
import ChatBuilder from '@/data/Classes/Chat/ChatBuilder';
import ChatList, { ChatListTab } from './ChatList';
import { ContactType } from '@common/types/enums';
import { PipelineStep } from '@common/PipelineManager/PipelineTypes';

const styles = {
  wrapper: {
    height: '100%',
    width: '100%',
    pr: 2,
    pt: 2,
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: '1fr'
  },
  filterWrapper: {},
  innerWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%'
  },
  contacts: {
    flex: '1 0 0',
    height: '100%'
  },
  chat: {
    flex: '2 0 0',
    height: '100%',
    boxSizing: 'border-box'
  },
  pipeline: {
    flex: '1 0 0',
    maxHeight: '100%',
    overflowY: 'overlay',
    pr: 1,
    boxSizing: 'border-box',
    position: 'relative',
    overflow: 'visible'
  },
  errorWrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  errorMessage: {
    textAlign: 'center',
    maxWidth: '50%',
    p: 4,
    borderRadius: 2,
    backgroundColor: (theme) => theme.palette.background.default
  }
} satisfies Styles;

export default function Chat() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { linkedInAccount } = useLinkedInAccount();
  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [tab, setTab] = React.useState<ChatListTab>('ssc:normal');

  const [search, setSearch] = React.useState('');
  const [filter, setFilter] = React.useState<ChatFilterType>({});

  const [loading, setLoading] = React.useState(false);

  const [openChat, setOpenChat] = React.useState<AbstractChat | null>(null);
  const openChatRef = React.useRef(openChat);
  openChatRef.current = openChat;

  const [activeStep, setActiveStep] = React.useState<PipelineStep | null>(null);
  React.useEffect(() => {
    if (!openChat?.contact) return;
    setActiveStep(
      openChat.contact.type === ContactType.POTENTIAL_CUSTOMER
        ? openChat.contact.getActiveStep()
        : null
    );
  }, [openChat]);

  // read chat from url search params
  React.useEffect(() => {
    if (!linkedInAccount) return;
    const conversationID = searchParams.get('conversationID');
    const profileID = searchParams.get('profileID');
    if (!profileID) return;
    (async () => {
      try {
        setLoading(true);
        const chat = await ChatBuilder.getChat(profileID, conversationID);
        if (!chat) return;
        await chat.getConversationData();
        if (!mounted.current) return;
        setOpenChat(chat);
      } finally {
        if (mounted.current) {
          setSearchParams({});
          setLoading(false);
        }
      }
    })();
  }, [searchParams, linkedInAccount]);

  const showSidebar = Boolean(
    openChat?.contact && openChat.contact.type !== ContactType.UNCATEGORIZED
  );

  return (
    <ChatContextProvider chat={openChat}>
      <Box sx={styles.wrapper}>
        <Box sx={styles.filterWrapper}>
          <OptionalTooltip
            title={
              !tab.startsWith('ssc:') && (
                <Typography>
                  {language.text.cant_use_filters_with_linkedin_chat}
                </Typography>
              )
            }>
            <Box pl={2}>
              <ChatFilter
                filter={filter}
                onChange={(filter) => {
                  setOpenChat(null);
                  setFilter(filter);
                }}
                disabled={!tab.startsWith('ssc:')}
              />
            </Box>
          </OptionalTooltip>
          <Divider sx={{ mt: 1 }} />
        </Box>
        <Box sx={styles.innerWrapper}>
          <Box sx={styles.contacts}>
            <ChatList
              tab={tab}
              onTabChange={(tab) => {
                setTab(tab);
                setOpenChat(null);
              }}
              headerContent={
                <Box
                  sx={{
                    position: 'absolute',
                    right: 0,
                    left: 0,
                    top: (theme) => theme.spacing(0.5),
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                  }}>
                  <SearchField
                    placeholder={language.text.search}
                    value={search}
                    onChange={setSearch}
                    sx={{
                      transition: (theme) =>
                        theme.transitions.create(['flex-grow', 'background']),
                      background: 'transparent'
                    }}
                    styles={{
                      open: {
                        flexGrow: 1,
                        background: (theme) => theme.palette.background.paper,
                        input: {
                          width: '100%'
                        }
                      }
                    }}
                  />
                </Box>
              }
              filter={{ ...filter, searchQuery: search }}
              openChat={openChat}
              onSelect={async (chat, initial) => {
                if (openChatRef.current && initial) return;
                // don't open chat if we are currently loading a chat from url search params
                if (
                  searchParams.get('conversationID') ??
                  searchParams.get('profileID')
                )
                  return;
                if (chat.participants.length !== 1) setOpenChat(chat);
                else {
                  setLoading(true);
                  if (!chat.contact) {
                    const contact = await ContactActions.getContact(
                      chat.participants[0].profileID
                    );
                    chat.contact = contact ?? undefined;
                  }
                  if (!mounted.current) return;
                  setOpenChat(chat);
                }
                setLoading(false);

                // mark chat as read when opening it
                if (!initial && chat.unread) {
                  chat
                    .markAsRead(true)
                    .catch((e: unknown) =>
                      log.error(
                        `Failed to mark chat as read of chat ${chat.conversationID ?? 'null'}:`,
                        e
                      )
                    );
                }
              }}
            />
          </Box>
          <Box sx={{ ...styles.chat, flexGrow: showSidebar ? 2 : 3 }}>
            <OpenChat
              tab={tab}
              chat={openChat}
              key={openChat?.conversationID}
              loading={loading}
              activeStep={activeStep}
            />
          </Box>
          {showSidebar && (
            <Box sx={styles.pipeline}>
              <Box position="absolute">
                <ChatSidebar
                  // hide sidebar while loading
                  contact={loading ? null : openChat?.contact ?? null}
                  key={
                    (openChat?.conversationID ?? 'no-chat') +
                    (openChat?.contact?.type ?? 'no-type')
                  }
                  onChangeStep={(step) => setActiveStep(step)}
                  onRecategorize={(category) =>
                    setActiveStep(
                      category === ContactType.POTENTIAL_CUSTOMER
                        ? openChat?.contact?.getActiveStep() ?? null
                        : null
                    )
                  }
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </ChatContextProvider>
  );
}
