import { Contact } from '@/data/Classes/Contact';
import { Theme } from '@mui/material';
import { language } from '@/index';
import { CSComment } from 'webview-preload';
import { Task, TaskConfig, TaskHeader } from 'focus-mode-scheduler';
import { TaskIncomingEventMap } from 'focus-mode-scheduler/src/Task/Task';
import tracking from 'tracking';

export default class CommentTask extends Task {
  public static get config(): TaskConfig {
    return {
      id: 'comment',
      info: {
        title: language.text.write_comments,
        description: language.text.write_comments_description
      },
      hide: false,
      constraintBreachedDialogContent: {
        items: {
          header: language.text.maximum_reached,
          body: language.text.maximum_comment_reached,
          buttons: {
            next: {
              text: language.text.next_step
            },
            oneMore: {
              text: language.text.one_more_comment,
              amount: 1
            }
          }
        },
        time: {
          header: language.text.time_is_up,
          body: language.text.you_reached_the_max_time_for_commenting,
          buttons: {
            next: {
              text: language.text.next_step
            },
            oneMore: {
              text: language.text.one_more_comment,
              amount: 2
            }
          }
        }
      }
    };
  }
  /**
   * Colors for different completion phases of the task
   */
  public static PHASES: {
    [key: number]: (theme: Theme) => string | undefined;
  } = {
    // 0: (theme) => theme.palette.error.light,
    0: (theme) => theme.palette.warning.main,
    2: (theme) => theme.palette.success.main
  };

  protected _header: TaskHeader = {
    title: language.text.write_comments,
    subtitle: language.text.write_comments_description
  };

  constructor(public readonly audienceHolders: Contact[]) {
    super();
    const startAudienceHolder =
      this.audienceHolders[
        Math.round(Math.random() * (this.audienceHolders.length - 1))
      ];
    this._startLink = startAudienceHolder
      ? startAudienceHolder.activityURL
      : '';
  }
  /**
   * Link where to start looking for new contacts (e.g. activity of audience holder)
   */
  private _startLink: string;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public commentPosted(comment: CSComment): void {
    tracking.capture('CommentPosted');
  }

  protected handleEvent<Type extends keyof TaskIncomingEventMap>(
    type: Type
  ): void {
    switch (type) {
      case 'complete':
      case 'skip':
        this.internalEmit('finished', undefined);
        break;
    }
  }

  public get startLink() {
    return this._startLink;
  }
}
