import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Fab,
  Popover,
  TextField,
  Typography
} from '@mui/material';
import { Styles } from '@/other/UtilTypes';
import { useManualMode } from '../../ManualModeContext';
import { FancyButton, SaveIcon, WritingIcon } from 'ui-utils';
import ContactActions from '@/data/DataServer/Contact';
import { useChatContext } from '../ChatContext';
import { language } from '@/index';
import ContactTags from '@/components/Contact/ContactTags';
import ContactAvatar from '@/components/Avatar/ContactAvatar';
import { AbstractChat } from '@/data/Classes/Chat/AbstractChat';
import { ContactType } from '@common/types/enums';
import { Person } from '@mui/icons-material';
import ChatRecategorize from './ChatRecategorize';

export interface ChatHeaderProps {
  chat: AbstractChat;
}

const styles = {
  rightWrapper: {
    display: 'grid',
    gridTemplateColumns: '4fr 1fr',
    gridColumnGap: (theme) => theme.spacing(1),
    flexGrow: 1,
    paddingLeft: (theme) => theme.spacing(1)
  },
  wrapper: {
    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
    borderRight: (theme) => `1px solid ${theme.palette.divider}`
  },
  upperWrapper: {
    width: '100%',
    position: 'relative',
    py: 1,
    px: 2,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center'
  },
  tagWrapper: {
    width: '100%',
    position: 'relative',
    overflowX: 'auto',
    px: 2,
    boxSizing: 'border-box',
    height: 40
  },
  text: {
    fontWeight: 500,
    fontSize: '1.1em',
    lineHeight: '1.2em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%'
  },
  textwrapper: {
    position: 'relative',
    height: '1.2em',
    alignSelf: 'center'
  },
  number: {
    height: '1.5rem',
    alignSelf: 'end',
    fontWeight: 500
  },
  groupWrapper: {
    flexGrow: 1,
    overflow: 'hidden',
    position: 'relative',
    minHeight: (theme) => theme.spacing(3)
  },
  groupNamesInner: {
    position: 'absolute',
    inset: 0,
    overflow: 'hidden'
  },
  textEllispsis: {
    whiteSpace: 'nowrap',
    wordBreak: 'keep-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  groupName: {
    textDecoration: 'none',
    color: 'inherit',
    whiteSpace: 'nowrap',
    wordBreak: 'keep-all',
    verticalAlign: 'super'
  },
  groupAvatar: {
    display: 'inline-block',
    cursor: 'pointer',
    height: (theme) => theme.spacing(3),
    width: (theme) => theme.spacing(3),
    fontSize: (theme) => theme.typography.caption.fontSize,
    mr: 0.5,
    // center initials if profile has no picture
    textAlign: 'center',
    lineHeight: (theme) => theme.spacing(3)
  }
} satisfies Styles;

export default function ChatHeader(props: ChatHeaderProps) {
  const { openLinkedIn } = useManualMode();
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- component could be used outside of context
  const { focusChatMessageBox } = useChatContext() ?? {};

  const openProfile =
    props.chat.participants[0]?.profileID === 'UNKNOWN' && !props.chat.contact
      ? undefined
      : () => {
          openLinkedIn(props.chat.contact ?? props.chat.participants[0]);
        };

  const { contact: remoteContact } = ContactActions.useContact(
    props.chat.profileID,
    { enabled: !props.chat.contact }
  );
  const contact = props.chat.contact ?? remoteContact;
  const notesAnchor = React.useRef<HTMLButtonElement>(null);
  const [notesOpen, setNotesOpen] = React.useState(false);
  const [notes, setNotes] = React.useState<string | null>(null);
  React.useEffect(() => {
    if (typeof notes !== 'string' && contact) setNotes(contact.notes);
  }, [contact]);
  const [notesSaving, setNotesSaving] = React.useState(false);

  const [recategorizeOpen, setRecategorizeOpen] = React.useState(false);

  const canCategorize =
    !props.chat.contact ||
    props.chat.contact.type === ContactType.UNCATEGORIZED ||
    props.chat.contact.type === ContactType.MARKED;

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.upperWrapper}>
        {props.chat.contact ?? props.chat.participants.length === 1 ? (
          <>
            <ContactAvatar
              hidePriority
              contact={
                contact ??
                props.chat.participants.slice(0, 1).map((p) => ({
                  firstname: p.firstName,
                  lastname: p.lastName,
                  name: p.firstName + ' ' + p.lastName,
                  pictures: p.profilePictureUrl
                }))[0]
              }
              sx={{
                cursor: !openProfile ? undefined : 'pointer'
              }}
              onClick={openProfile}
            />
            <Box sx={styles.rightWrapper}>
              <Box sx={styles.textwrapper}>
                <Typography
                  sx={{
                    ...styles.text,
                    '&:hover': !openProfile
                      ? {}
                      : {
                          cursor: 'pointer',
                          textDecoration: 'underline'
                        }
                  }}
                  onClick={openProfile}>
                  {props.chat.participants.length
                    ? props.chat.participants
                        .map((p) =>
                          props.chat.participants[0].profileID === 'UNKNOWN'
                            ? '[Gelöschter Account]'
                            : p.firstName + ' ' + p.lastName
                        )
                        .join(', ')
                    : props.chat.contact?.name ?? ''}
                </Typography>
              </Box>
              <Box display="flex" gap={1}>
                {props.chat.contact && (
                  <FancyButton
                    ref={notesAnchor}
                    fType={{ promise: true }}
                    loading={notes === null}
                    variant="text"
                    color="primary"
                    startIcon={
                      <Box
                        sx={{
                          width: (theme) => theme.spacing(3),
                          height: (theme) => theme.spacing(3),
                          color: 'primary.main'
                        }}>
                        <WritingIcon />
                      </Box>
                    }
                    onClick={() => setNotesOpen(true)}>
                    {language.text.notes}
                  </FancyButton>
                )}
                <Popover
                  open={notesOpen}
                  anchorEl={notesAnchor.current}
                  onClose={() => {
                    if (notes !== null) props.chat.contact?.setNotes(notes);
                    setNotesOpen(false);
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- component could be used outside of context
                    focusChatMessageBox?.();
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}>
                  <Box
                    sx={{
                      position: 'relative',
                      width: '20rem'
                    }}>
                    <TextField
                      autoFocus={notesOpen}
                      multiline
                      rows={6}
                      placeholder={language.text.make_notes_about_person}
                      variant="outlined"
                      value={notes ?? ''}
                      onChange={(e) => setNotes(e.target.value)}
                      fullWidth
                      sx={{
                        textArea: {
                          backgroundColor: (theme) =>
                            theme.palette.background.paper
                        }
                      }}
                    />
                    <Fab
                      variant="circular"
                      color="primary"
                      size="small"
                      disabled={notesSaving}
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises -- this prop expects a promise
                      onClick={async () => {
                        if (notes === null) return;
                        try {
                          setNotesSaving(true);
                          // add some delay to make sure the user sees the loading state
                          await props.chat.contact?.setNotes(notes);
                        } finally {
                          setNotesSaving(false);
                          setNotesOpen(false);
                        }
                      }}
                      sx={{
                        position: 'absolute',
                        bottom: (theme) => theme.spacing(1),
                        right: (theme) => theme.spacing(1),
                        boxShadow: 'none'
                      }}>
                      {notesSaving ? (
                        <CircularProgress size="1em" />
                      ) : (
                        <SaveIcon />
                      )}
                    </Fab>
                  </Box>
                </Popover>
                {canCategorize && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<Person />}
                      onClick={() => setRecategorizeOpen(true)}>
                      {language.text.categorize}
                    </Button>
                    <ChatRecategorize
                      contact={props.chat.contact}
                      profileID={props.chat.profileID}
                      open={recategorizeOpen}
                      onClose={() => setRecategorizeOpen(false)}
                    />
                  </>
                )}
              </Box>
            </Box>
          </>
        ) : (
          <Box sx={styles.groupWrapper}>
            <Box sx={styles.groupNamesInner}>
              <Typography sx={styles.textEllispsis}>
                {props.chat.participants.map((p, i) => (
                  <React.Fragment key={p.profileID}>
                    <ContactAvatar
                      component="span"
                      contact={{
                        firstname: p.firstName,
                        lastname: p.lastName,
                        name: p.firstName + ' ' + p.lastName,
                        pictures: p.profilePictureUrl
                      }}
                      sx={styles.groupAvatar}
                      onClick={() => openLinkedIn(p)}
                    />
                    <Typography
                      component="span"
                      sx={{
                        ...styles.groupName,
                        ...(props.chat.participants[0].profileID !==
                          'UNKNOWN' && {
                          '&:hover': {
                            cursor: 'pointer',
                            textDecoration: 'underline'
                          }
                        })
                      }}
                      onClick={
                        props.chat.participants[0].profileID === 'UNKNOWN'
                          ? undefined
                          : () => openLinkedIn(p)
                      }>
                      {props.chat.participants[0].profileID === 'UNKNOWN'
                        ? '[Gelöschter Account]'
                        : p.firstName + ' ' + p.lastName}
                    </Typography>
                    {i < props.chat.participants.length - 1 && (
                      <Typography
                        component="span"
                        marginRight={0.5}
                        sx={{ verticalAlign: 'super' }}>
                        ,
                      </Typography>
                    )}
                  </React.Fragment>
                ))}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      {props.chat.contact && (
        <Box sx={styles.tagWrapper}>
          <ContactTags
            contact={contact}
            editable
            mb={1}
            flexWrap="nowrap"
            position="absolute"
          />
        </Box>
      )}
    </Box>
  );
}
