import { language } from '@/index';
import { APP_PATHS, LOADER_PATHS, POST_LOADER_PATHS } from '@/globals/Paths';
import { Update } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Background } from 'ui-utils';

export default function PostLoaderStart() {
  const navigate = useNavigate();
  return (
    <Background
      variant="frame"
      height="100vh"
      width="100%"
      overflow="auto"
      p={4}
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={2}>
      <Typography maxWidth="1000px" variant="h1">
        <Update
          sx={{
            verticalAlign: 'middle',
            fontSize: '3rem',
            mr: 2
          }}
        />
        {language.text.load_posts}
      </Typography>
      <Typography maxWidth="800px">
        {language.text.load_posts_description}
      </Typography>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
        <Button
          variant="contained"
          color="neutral"
          size="large"
          sx={{
            minWidth: '200px'
          }}
          onClick={() => navigate(`/${APP_PATHS.Manual}`)}>
          {language.text.back}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{
            minWidth: '200px'
          }}
          onClick={() =>
            navigate(
              `/${APP_PATHS.Loader}/${LOADER_PATHS.Post}/${POST_LOADER_PATHS.Run}`
            )
          }>
          {language.text.load_posts}
        </Button>
      </Box>
    </Background>
  );
}
