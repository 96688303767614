import { statistics } from '@digital-sun-solutions/cloud-functions';
if (typeof window === 'undefined') {
    throw new Error('Tracking can only be used in renderer process');
}
if (typeof window === 'undefined') {
    process.exit(1);
}
const MAX_SAVE_TIMEOUT = 1000 * 60 * 2;
class Tracking {
    token;
    eventsMap;
    saveTimeout;
    constructor() {
        this.eventsMap = new Map();
    }
    init(token) {
        this.token = token;
    }
    /**
     * Captures a tracking event. Events will be cached locally and send in batches to the cloud.
     * @param event Event to capture
     * @param count Number of times the event was captured (default: 1)
     */
    capture(event, count = 1) {
        if (this.token === undefined) {
            console.warn('Tracking event received before initialization. The event will be stored locally and sent to the cloud when initialization is complete.');
        }
        const currentEventCount = this.eventsMap.get(event) ?? 0;
        this.eventsMap.set(event, currentEventCount + count);
        if (event === 'FocusModeStart' || event === 'FocusModeFinished') {
            this.saveEventsToCloud().then((success) => {
                if (!success)
                    throw new Error('Tracking not initialized but focus mode event was sent. ');
            });
        }
        else {
            this.scheduleCloudSave();
        }
    }
    userStateInterval = null;
    captureUserStateChange(state) {
        if (this.userStateInterval) {
            clearInterval(this.userStateInterval);
        }
        if (state === 'active')
            this.userStateInterval = setInterval(() => {
                this.capture('UserActive');
            }, 60 * 1000);
    }
    scheduleCloudSave() {
        if (!this.saveTimeout) {
            this.saveTimeout = setTimeout(async () => {
                if (await this.saveEventsToCloud()) {
                    this.saveTimeout = undefined;
                }
                else {
                    console.warn('Could not save tracking events: Tracking not initialized. Will try again later.');
                    this.scheduleCloudSave();
                }
            }, MAX_SAVE_TIMEOUT);
        }
    }
    async saveEventsToCloud() {
        if (this.token === undefined) {
            return false;
        }
        await statistics.track({
            events: this.getEventsToSave()
        }, {
            token: this.token,
            FetchOptions: {
                keepalive: true
            }
        });
        this.eventsMap.clear();
        return true;
    }
    getEventsToSave() {
        const eventsToSave = [];
        for (const [eventName, count] of this.eventsMap) {
            if (count > 0) {
                eventsToSave.push({
                    eventType: eventName,
                    count
                });
            }
        }
        return eventsToSave;
    }
}
const tracking = new Tracking();
export default tracking;
