import React from 'react';
import { AbstractChat, ChatEvent } from '@/data/Classes/Chat/AbstractChat';
import { useEventEmitter } from 'ui-utils';
import { LinkedInContact, Message } from 'linkedin-domain-types';
import { useLinkedInAccount } from '@/data/LinkedIn/Account';

export type ChatInfoVariant = 'info' | 'error' | 'warning' | 'success';

export interface ChatContextType {
  setChatMessage: (message: string, options?: { soft?: boolean }) => void;
  focusChatMessageBox: () => void;
  displayChatMessageBoxInfo: (info: string, variant?: ChatInfoVariant) => void;
  registerChatMessageBox: (props: {
    setContent: (content: string, options?: { soft?: boolean }) => void;
    focus: () => void;
    displayInfo: (info: string, variant?: ChatInfoVariant) => void;
  }) => void;
  loadedChatData: {
    messages?: Message[];
    profile: (LinkedInContact & { conversationID?: string }) | null;
    noMoreMessages?: boolean;
  };
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ChatContext = React.createContext<ChatContextType>(null!);

export function useChatContext() {
  return React.useContext(ChatContext);
}

export function ChatContextProvider(props: {
  children: JSX.Element;
  chat: AbstractChat | null;
}) {
  const { linkedInAccount } = useLinkedInAccount();
  const [setChatMessageFct, setSetChatMessageFct] = React.useState<
    (message: string) => void
    // eslint-disable-next-line @typescript-eslint/no-empty-function -- default value
  >(() => () => {});
  // eslint-disable-next-line @typescript-eslint/no-empty-function -- default value
  const [focusFct, setFocusFct] = React.useState<() => void>(() => () => {});
  const [displayInfoFct, setDisplayInfoFct] = React.useState<
    (info: string, variant?: ChatInfoVariant) => void
    // eslint-disable-next-line @typescript-eslint/no-empty-function -- default value
  >(() => () => {});

  const [loadedChatData, setLoadedChatData] = React.useState<
    ChatContextType['loadedChatData']
  >({ profile: null });

  useEventEmitter(props.chat, {
    'historic-messages': (messages: ChatEvent['historic-messages']) => {
      const newMessages: Message[] = messages.map((m) => ({
        attachments: m.attachments,
        createdAt: m.createdAt,
        deleted: m.deleted,
        messageID: m.messageID,
        reactions: m.reactions,
        text: m.text ?? '',
        sentFrom:
          (m.sendByYou
            ? linkedInAccount?.publicIdentifier
            : props.chat?.contact?.publicIdentifier ??
              props.chat?.participants.at(0)?.publicIdentifier) ??
          (() => {
            throw new Error(
              `Could not get public identifier for chat ${props.chat?.contact?.profileID ?? 'UNDEFINED'}`
            );
          })()
      }));
      setLoadedChatData((prev) => ({
        ...prev,
        messages: (prev.messages?.concat(newMessages) ?? newMessages)
          .filter(
            (message, index, messages) =>
              messages.findIndex((m) => m.messageID === message.messageID) ===
              index
          )
          .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime()),
        noMoreMessages: newMessages.length === 0
      }));
    }
  });

  React.useEffect(() => {
    if (!props.chat) setLoadedChatData({ profile: null });
    setLoadedChatData((prev) => {
      const newProfile = props.chat?.contact
        ? {
            firstName: props.chat.contact.firstname,
            lastName: props.chat.contact.lastname,
            infoText: props.chat.contact.infoText,
            profileID: props.chat.contact.profileID,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            profilePictureUrl: props.chat.contact.pictures!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            publicIdentifier: props.chat.contact.publicIdentifier!,
            isConnection: props.chat.contact.connected,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            conversationID: props.chat.conversationID!
          }
        : null;
      if (prev.profile?.profileID !== newProfile?.profileID) {
        return {
          messages: [],
          profile: newProfile
        };
      } else {
        return {
          ...prev,
          profile: newProfile ?? prev.profile
        };
      }
    });
  }, [props.chat]);

  const exportValue = {
    setChatMessage: setChatMessageFct,
    focusChatMessageBox: focusFct,
    displayChatMessageBoxInfo: displayInfoFct,
    registerChatMessageBox: ({
      setContent,
      focus,
      displayInfo
    }: {
      setContent: (content: string) => void;
      focus: () => void;
      displayInfo: (info: string, variant?: ChatInfoVariant) => void;
    }) => {
      setSetChatMessageFct(() => setContent);
      setFocusFct(() => focus);
      setDisplayInfoFct(() => displayInfo);
    },
    loadedChatData
  } satisfies ChatContextType;

  window.dev.ChatContext = exportValue;

  return (
    <ChatContext.Provider value={exportValue}>
      {props.children}
    </ChatContext.Provider>
  );
}
