import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { wait } from '@idot-digital/generic-helpers';
import { LinkedInWebviewElement } from '@common/Webview.renderer/Base/ContentScriptTypes';
import MainConfig from '@common/config/MainConfig';
import useIPC from '@/hooks/IPCHook';
import { language } from '@/index';
import HUD from '@/components/HUD/HUD';
import HUDTitle from '@/components/HUD/HUDTitle';
import LinkedInWebview from '@common/Webview.renderer/Base/LinkedInWebview';
import { CSEventData, CSEventType } from 'webview-preload';
import { Loading } from 'ui-utils';

// ms to wait for linkedin page to load and show non login screen
const LINKEDIN_LOAD_TIMOUT = 4000;

/**
 * LinkedIn login page in focus mode
 * IMPORTANT!: linkedin auth cookies can only be intercepted by main process which automatically
 * closes the window after login
 */
export default function LinkedInLogin() {
  /**
   * key to reload linkedin webview
   */
  const [linkedInKey, reloadLinkedIn] = React.useReducer(
    (key: number) => key + 1,
    0
  );
  /**
   * is user logged in to linkedin
   */
  const [loggedIn, setLoggedIn] = React.useState(false);
  /**
   * is linkedin page loaded and ready to show
   */
  const [loaded, setLoaded] = React.useState(false);

  const [show, setShow] = React.useState(false);

  const [showWrongAccount, setShowWrongAccount] = React.useState(false);

  const [ready, setReady] = React.useState(false);

  const linkedinRef = React.useRef<LinkedInWebviewElement>(null);

  // show linkedin page after timeout -> check if already logged in
  React.useEffect(() => {
    if (!loaded || loggedIn || !ready) return;
    let mounted = true;

    (async () => {
      await wait(LINKEDIN_LOAD_TIMOUT);
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- could have changed due to async code
      if (!mounted) return;

      // check if automatically redirected to feed
      if (linkedinRef.current?.src.includes('/feed/')) {
        setLoggedIn(true);
        return;
      }
      // otherwise show login page
      linkedinRef.current?.loadURL(MainConfig.linkedInLoginUrl);

      setShow(true);
    })();

    return () => {
      mounted = false;
    };
  }, [loaded, loggedIn, ready]);

  const messageHandler = React.useCallback(
    <Type extends CSEventType>(channel: Type, data: CSEventData<Type>) => {
      if (channel === 'linkedin:login-status') {
        const loggedIn = data as CSEventData<'linkedin:login-status'>;
        setLoggedIn(loggedIn);
      }
    },
    []
  );

  useIPC('linkedin:login-wrong_account', () => {
    setShowWrongAccount(true);
    reloadLinkedIn();
  });

  const showLinkedIn = show && !loggedIn && !showWrongAccount;

  return (
    <Box height="100%" width="100%" position="relative">
      {showWrongAccount && (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 4
          }}>
          <Typography variant="h4" textAlign="center">
            {language.text.wrong_linkedin_account}
          </Typography>
          <Typography variant="body1" textAlign="center">
            {language.text.wrong_linkedin_account_explanation}
          </Typography>
          <Button
            onClick={() => setShowWrongAccount(false)}
            variant="contained"
            color="primary"
            size="large">
            {language.text.retry}
          </Button>
        </Box>
      )}
      {!showLinkedIn && !showWrongAccount && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            p: 2,
            boxSizing: 'border-box'
          }}>
          <Box>
            <Loading />
          </Box>
          <Typography variant="h6" textAlign="center">
            {loggedIn
              ? language.text.processing_linkedin_data
              : language.text.getting_login_status}
            ...
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          top: 0,
          position: showLinkedIn ? null : 'absolute',
          visibility: showLinkedIn ? null : 'hidden',
          pointerEvents: showLinkedIn ? null : 'none',
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}>
        <HUD
          topContent={
            <HUDTitle
              title={language.text.linkedin_login}
              subtitle={language.text.please_log_in_to_linkedin}
            />
          }
        />
        <Box
          sx={{
            width: 'calc(95% - 180px)',
            height: '100%',
            maxHeight: '100vh',
            display: 'flex',
            overflowY: 'auto',
            position: 'relative',
            boxSizing: 'border-box',
            margin: '0 auto',
            paddingTop: (theme) => theme.spacing(7.5),
            paddingBottom: (theme) => theme.spacing(5)
          }}>
          <LinkedInWebview
            showAdvancedOptions
            buttonPlacement="webview"
            ref={linkedinRef}
            key={linkedInKey}
            disableLinkedInLoginHandling
            messageHandler={messageHandler}
            startURL={MainConfig.linkedInUrl}
            onLinkedInLoaded={() => {
              setLoaded(true);
            }}
            onWebviewReady={() => setReady(true)}
          />
        </Box>
      </Box>
    </Box>
  );
}
