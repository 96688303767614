import { LOADER_PATHS } from '@/globals/Paths';
import { Route, Routes } from 'react-router-dom';
import PostLoaderRouter from './PostLoader/PostLoaderRouter';

export default function LoaderRouter() {
  return (
    <Routes>
      <Route path={`${LOADER_PATHS.Post}/*`} element={<PostLoaderRouter />} />
    </Routes>
  );
}
