import {
  BugReport,
  Check,
  CloudSync,
  Home,
  Info,
  Replay,
  Settings
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  Tooltip,
  Button,
  Popover,
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Typography
} from '@mui/material';
import React from 'react';
import MainConfig from '@common/config/MainConfig';
import DevConfig from '@common/config/DevConfig';
import {
  ContentScriptEvent,
  LinkedInWebviewElement
} from './ContentScriptTypes';
import { useLinkedInAccount } from '@/data/LinkedIn/Account';
import { FullProfile, MarkedProfile } from '@common/types/ApiTypes';
import ContactActions from '@/data/DataServer/Contact';
import { Contact } from '@/data/Classes/Contact';
import { language } from '@/index';
import { useWebview } from './WebviewHook';
import { useSnackbar } from 'notistack';
import { ContactType } from '@common/types/enums';
import WebviewLinkedIn from '../WebviewLinkedIn';
import {
  CSEventData,
  CSEventType,
  CSFeatures,
  CSProfileType
} from 'webview-preload';
import log from 'electron-log';
import { FancyButton, MountDOM } from 'ui-utils';
import MessageBus from '@common/MessageBus/MessageBus.renderer';
import { LinkedInContact } from 'linkedin-domain-types';
import Logger from 'electron-log';
import * as Sentry from '@sentry/electron/renderer';
import LinkedInLoadingManager from '@common/LoadingManager/LinkedInLoadingManager.renderer';

const LINKEDIN_URL = MainConfig.linkedInUrl;

export type WebviewMessageListener = <Event extends CSEventType>(
  channel: Event,
  data: CSEventData<Event>,
  ref: LinkedInWebviewElement | null
) => void;

export interface LinkedInWebviewProps {
  visible?: boolean;
  features?: CSFeatures[];
  children?: React.ReactNode;
  messageHandler?: WebviewMessageListener;
  startURL?: string;
  onWebviewReady?: (webview: LinkedInWebviewElement) => void;
  onLinkedInLoaded?: (webview: LinkedInWebviewElement) => void;
  /**
   * Called on click -> data is getting fetched from server
   */
  onOpenChatInitial?: () => void;
  /**
   * Called when data is fetched from server
   */
  onOpenChat?: (contact: FullProfile) => boolean;
  /**
   * Called when error occurs during data load for `openChat`
   */
  onOpenChatError?: () => void;
  on404?: (ref: LinkedInWebviewElement) => void;
  onProfileTypeChanged?: (data: {
    profile: MarkedProfile;
    contact?: Contact;
    newType: CSProfileType;
  }) => void;
  disableLinkedInLoginHandling?: boolean;
  peopleToHide?: string[];
  hideReloadButton?: boolean;
  hideHomeButton?: boolean;
  buttonPlacement?: 'hud' | 'webview';
  showAdvancedOptions?: boolean;
}

const WEB_AUTH_KEY = 'webview:web-auth';

const LinkedInWebview = React.forwardRef<
  LinkedInWebviewElement,
  LinkedInWebviewProps
>(function LinkedInWebview(props, ref) {
  const { enqueueSnackbar } = useSnackbar();

  const advancedOptionsAnchor = React.useRef<HTMLButtonElement | null>(null);
  const [advancedOptionsOpen, setAdvancedOptionsOpen] = React.useState(false);
  const [webAuthEnabled, setWebAuthEnabled] = React.useState(
    localStorage.getItem(WEB_AUTH_KEY) === 'true'
  );

  const link = props.startURL ?? LINKEDIN_URL;

  const ipcMessageListener = React.useCallback<WebviewMessageListener>(
    async (channel, data, webview) => {
      if (DevConfig.emitWebviewIPCtoMessageBus) {
        MessageBus.getInstance().emit(
          `webview:from:${channel}`,
          //@ts-expect-error
          { channel, data, webview: webview?.id ?? '' }
        );
      }
      switch (channel) {
        case 'ssc:features':
          webview?.send('ssc:features', props.features);
          break;
        case 'linkedin:loaded':
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- when linkedin inside of webview is loaded, webview is not null
          props.onLinkedInLoaded?.(webview!);
          break;
        case 'chat:open':
          await (async () => {
            const chat = data as CSEventData<'chat:open'>;
            if (!chat.id) return;
            props.onOpenChatInitial?.();
            const linkedInProfile = await WebviewLinkedIn.getProfile(chat.id);
            if (!linkedInProfile) {
              props.onOpenChatError?.();
              return;
            }
            const profile = chat.id
              ? await ContactActions.getFullProfile(linkedInProfile.profileID)
              : null;
            if (!profile) {
              props.onOpenChatError?.();
              return;
            }
            if (props.onOpenChat) {
              props.onOpenChat(profile);
            } else {
              enqueueSnackbar(language.text.error_while_opening_chat, {
                variant: 'error'
              });
            }
          })();
          break;
        case 'linkedin:login-status':
          (() => {
            const isLoggedIn =
              data as ContentScriptEvent<'linkedin:login-status'>['args'][0];
            setLoggedIn(isLoggedIn);
            WebviewLinkedIn.loggedIn = isLoggedIn;
            if (props.disableLinkedInLoginHandling) {
              props.messageHandler?.(channel, data, webview);
            }
          })();
          break;
        case 'linkedin:own-account':
          (() => {
            const linkedInAccount = linkedInAccountRef.current;
            if (!linkedInAccount) webview?.send('linkedin:own-account', null);
            else
              webview?.send('linkedin:own-account', {
                firstName: linkedInAccount.firstName,
                lastName: linkedInAccount.lastName,
                profileID: linkedInAccount.profileID,
                publicIdentifier: linkedInAccount.publicIdentifier,
                profilePicture: linkedInAccount.profilePictureUrl['800']
              });
          })();
          break;
        case 'linkedin:get-people-to-hide':
          webview?.send('linkedin:people-to-hide', props.peopleToHide ?? []);
          break;
        case 'ssc:audience-holders':
          if (!audienceHoldersRef.current)
            waitingForAudienceHolderProfiles.current = true;
          else
            webview?.send(
              'ssc:audience-holders',
              audienceHoldersRef.current.map((c) => ({
                firstName: c.firstname,
                lastName: c.lastname,
                profileID: c.profileID,
                publicIdentifier: c.publicIdentifier ?? ''
              }))
            );

          break;
        case 'ssc:get-lang':
          webview?.send('ssc:lang', language.getCSText());
          break;
        case 'ssc:release-channel':
          webview?.send('ssc:release-channel', globalThis.RELEASE_CHANNEL);
          break;
        case 'linkedin:404':
          if (webview) props.on404?.(webview);
          break;
        case 'linkedin:get-profile-type':
          await (async () => {
            const profileData =
              data as CSEventData<'linkedin:get-profile-type'>;
            const type = await ContactActions.getProfileType(profileData.id);
            webview?.send('linkedin:profile-type', {
              profileID: profileData.id,
              type
            });
          })();
          break;
        case 'linkedin:toggle-profile':
          await (async () => {
            const toggleProfileData =
              data as CSEventData<'linkedin:toggle-profile'>;
            const {
              type: newType,
              contact,
              profile: newProfile
            } = await toggleProfile(toggleProfileData);
            webview?.send('linkedin:profile-saved-type', {
              profileID: toggleProfileData.id,
              type: newType
            });
            let linkedinProfile: LinkedInContact | null = null;
            if (!newProfile) {
              linkedinProfile = await WebviewLinkedIn.getProfile(
                toggleProfileData.id
              );
              if (!linkedinProfile) {
                Logger.error(
                  '[LinkedInWebview:linkedin:toggle-profile] Could not get profile data from LinkedIn',
                  toggleProfileData.id
                );
                return;
              }
            }
            props.onProfileTypeChanged?.({
              profile: newProfile ?? {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked above
                firstname: linkedinProfile!.firstName,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked above
                lastname: linkedinProfile!.lastName,
                notes: '',
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked above
                profileID: linkedinProfile!.profileID,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked above
                pictures: linkedinProfile!.profilePictureUrl,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked above
                publicIdentifier: linkedinProfile!.publicIdentifier,
                priority: 0
              },
              newType,
              contact
            });
          })();
          break;
        case 'logger:error':
          Sentry.captureException(
            `Webview error: ${data as CSEventData<'logger:error'>[0]}`,
            {
              attachments: [
                {
                  filename: 'error.json',
                  data: JSON.stringify(data ?? null)
                },
                {
                  filename: 'webview.html',
                  data:
                    (await webview?.executeJavaScript<string>(
                      'document.documentElement.outerHTML'
                    )) ?? 'Webview not available'
                }
              ]
            }
          );
          Logger.error(
            `[Webview:error][${webview?.id ?? '-1'}]`,
            ...(data as CSEventData<'logger:error'>)
          );
          break;
        case 'logger:warn':
          Logger.warn(
            `[Webview:warn][${webview?.id ?? '-1'}]`,
            ...(data as CSEventData<'logger:warn'>)
          );
          break;
        case 'logger:info':
          Logger.info(
            `[Webview:info][${webview?.id ?? '-1'}]`,
            ...(data as CSEventData<'logger:info'>)
          );
          console.info(
            '[Webview:info]',
            ...(data as CSEventData<'logger:info'>)
          );
          break;
        case 'linkedin:realtime-event':
          LinkedInLoadingManager.handleRealtimeEvent(
            data as CSEventData<'linkedin:realtime-event'>
          );
          break;
        default:
          props.messageHandler?.(channel, data, webview);
      }
    },
    [props.features?.join(',')]
  );
  const { webview, webviewOvertaken, webviewReady } = useWebview({
    ipcMessageListener,
    webAuthEnabled,
    link
  });

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- when webview is ready, it is not null
    if (webviewReady) props.onWebviewReady?.(webview!);
  }, [webviewReady]);

  const [loggedIn, setLoggedIn] = React.useState<boolean>(false);
  // only load linkedInAccount if logged in
  const { linkedInAccount } = useLinkedInAccount({ enabled: loggedIn });
  // use ref for less remounting listeners on webview due to data change
  const linkedInAccountRef = React.useRef(linkedInAccount);
  linkedInAccountRef.current = linkedInAccount;

  // set ref to be able to forward ref
  React.useEffect(() => {
    if (typeof ref === 'function') ref(webview);
    else if (ref) ref.current = webview;
  }, [webview]);

  const { contacts: audienceHolders } = ContactActions.useAllContacts(
    {
      values: {
        type: ContactType.AUDIENCE_HOLDER
      }
    },
    {
      // don't fetch when not needed anyways (when feature is not enabled)
      enabled:
        props.features?.includes('highlight-audience-holders-posts') ?? false,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );

  // use ref to avoid remounting listeners on data change
  const audienceHoldersRef = React.useRef<null | Contact[]>(null);
  if (!audienceHolders || audienceHolders.length === 0)
    audienceHoldersRef.current = null;
  else audienceHoldersRef.current = audienceHolders;

  // if the content scripts asks for the audience holder profiles before they are loaded, we need to wait for them
  const waitingForAudienceHolderProfiles = React.useRef(false);
  React.useEffect(() => {
    if (audienceHolders && waitingForAudienceHolderProfiles.current) {
      webview?.send(
        'ssc:audience-holders',
        audienceHolders.map((c) => ({
          firstName: c.firstname,
          lastName: c.lastname,
          profileID: c.profileID,
          publicIdentifier: c.publicIdentifier ?? ''
        }))
      );
      waitingForAudienceHolderProfiles.current = false;
    }
  }, [audienceHolders]);

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        position: 'relative',
        display: props.visible ?? true ? 'flex' : 'none',
        flexDirection: 'column'
      }}>
      {webviewOvertaken && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 100,
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
              p: 2,
              background: (theme) => theme.palette.background.default,
              borderRadius: 1,
              boxShadow: 1
            }}>
            <CloudSync color="primary" sx={{ fontSize: 48 }} />
            <Typography variant="h6">
              {language.text.webview_overtaken}
            </Typography>
            <Typography variant="body1">
              {language.text.webview_overtaken_description}
            </Typography>
          </Box>
        </Box>
      )}
      <MountDOM element={webview} />
      {props.children && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
            padding: 1
          }}>
          {props.children}
        </Box>
      )}
      {DevConfig.linkedInDevTools && (
        <IconButton
          onClick={() => {
            webview?.openDevTools();
          }}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0
          }}>
          <BugReport />
        </IconButton>
      )}
      {(!props.hideReloadButton ||
        !props.hideHomeButton ||
        props.showAdvancedOptions) && (
        <Box
          sx={{
            zIndex: 100,
            display: 'flex',
            gap: 2,
            ...(props.buttonPlacement === 'webview'
              ? {
                  position: 'absolute',
                  top: (theme) => theme.spacing(2),
                  left: (theme) => theme.spacing(2)
                }
              : {
                  position: 'fixed',
                  top: (theme) => theme.spacing(1.5),
                  left: (theme) => theme.spacing(13.75)
                })
          }}>
          <Tooltip title={language.text.reload}>
            <FancyButton
              fType={{ promise: true }}
              variant="contained"
              color="secondary"
              onClick={async () => {
                try {
                  if (!webview) return;
                  if (webview.src.includes('linkedin.com')) webview.reload();
                  else await webview.loadURL(link);
                } catch (e) {
                  log.error('Error while reloading webview', e);
                }
              }}
              sx={{
                pl: 0,
                pr: 0,
                minWidth: '36px',
                borderRadius: '50%'
              }}>
              <Replay />
            </FancyButton>
          </Tooltip>
          <Tooltip title={language.text.home}>
            <FancyButton
              fType={{ promise: true }}
              variant="contained"
              color="secondary"
              onClick={async () => {
                try {
                  if (!webview) return;
                  await webview.loadURL(link);
                } catch (e) {
                  log.error('Error while going home in webview', e);
                }
              }}
              sx={{
                pl: 0,
                pr: 0,
                minWidth: '36px',
                borderRadius: '50%'
              }}>
              <Home />
            </FancyButton>
          </Tooltip>
          {props.showAdvancedOptions && (
            <>
              <Tooltip title={language.text.advanced_options}>
                <Button
                  ref={advancedOptionsAnchor}
                  variant="contained"
                  color="neutral"
                  onClick={() => setAdvancedOptionsOpen(true)}
                  sx={{
                    pl: 0,
                    pr: 0,
                    minWidth: '36px',
                    borderRadius: '50%'
                  }}>
                  <Settings />
                </Button>
              </Tooltip>
              <Popover
                open={advancedOptionsOpen}
                onClose={() => setAdvancedOptionsOpen(false)}
                anchorEl={advancedOptionsAnchor.current}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}>
                <MenuList>
                  <Typography variant="h6" sx={{ px: 1 }}>
                    {language.text.advanced_options}
                  </Typography>
                  <MenuItem
                    onClick={() =>
                      setWebAuthEnabled((enabled) => {
                        localStorage.setItem(
                          WEB_AUTH_KEY,
                          (!enabled).toString()
                        );
                        return !enabled;
                      })
                    }>
                    {webAuthEnabled && (
                      <ListItemIcon>
                        <Check />
                      </ListItemIcon>
                    )}
                    <ListItemText inset={!webAuthEnabled}>
                      {language.text.web_auth}
                      <Tooltip
                        title={
                          <Typography>
                            {language.text.web_auth_description}
                          </Typography>
                        }>
                        <IconButton size="small" sx={{ float: 'right', p: 0 }}>
                          <Info color="disabled" />
                        </IconButton>
                      </Tooltip>
                    </ListItemText>
                  </MenuItem>
                </MenuList>
              </Popover>
            </>
          )}
        </Box>
      )}
    </Box>
  );
});

export default LinkedInWebview;

async function toggleProfile(
  cs_profile: CSEventData<'linkedin:toggle-profile'>
): Promise<{
  type: CSProfileType;
  contact?: Contact;
  profile?: MarkedProfile;
}> {
  try {
    if (cs_profile.type === 'marked') {
      if (cs_profile.actionType === 'save') {
        const markedProfile = convertToMarkedProfile(
          await WebviewLinkedIn.getProfile(cs_profile.id)
        );
        if (!markedProfile) return { type: 'unknown' };
        await ContactActions.addMarkedProfile(markedProfile);
        return {
          type: 'marked',
          profile: markedProfile
        };
      } else if (cs_profile.actionType === 'delete') {
        const profileID = (await ContactActions.getMarkedProfiles()).find(
          (p) =>
            p.publicIdentifier === cs_profile.id ||
            p.profileID === cs_profile.id
        )?.profileID;
        if (!profileID) return { type: 'unknown' };
        await ContactActions.removeMarkedProfile(profileID);
        return { type: 'unknown' };
      } else {
        console.warn(
          "[LinkedInWebview:toggleProfile] Missing actionType for 'marked'"
        );
      }
    }

    let contact = await ContactActions.getContact(cs_profile.id, true);
    if (!contact || contact.type === ContactType.UNCATEGORIZED) {
      if (!contact && cs_profile.actionType === 'delete')
        return { type: 'unknown' };
      contact = await ContactActions.createContactFromLinkedInAPI(
        cs_profile.id
      );
    }

    if (!contact) return { type: 'unknown' };

    if (cs_profile.actionType === 'delete') {
      if (contact.connected)
        await contact.setContactType(ContactType.UNCATEGORIZED);
      else await contact.delete();
      return { type: 'unknown' };
    }

    await Contact.categorize(
      contact,
      convertCSProfileTypeToContactType(cs_profile.type)
    );
    return {
      type: cs_profile.type,
      contact
    };
  } catch (e) {
    console.error('[LinkedInWebview:toggleProfile]', e);
    return { type: 'unknown' };
  }
}

function convertToMarkedProfile(
  profile: LinkedInContact | null | undefined
): MarkedProfile | undefined {
  if (!profile) return undefined;
  return {
    firstname: profile.firstName,
    lastname: profile.lastName,
    notes: '',
    profileID: profile.profileID,
    pictures: profile.profilePictureUrl,
    publicIdentifier: profile.publicIdentifier,
    priority: 0
  };
}

function convertCSProfileTypeToContactType(type: CSProfileType): ContactType {
  switch (type) {
    case 'audienceHolder':
      return ContactType.AUDIENCE_HOLDER;
    case 'marked':
      return ContactType.MARKED;
    case 'customer':
      return ContactType.CUSTOMER;
    case 'no_match':
      return ContactType.NO_MATCH;
    case 'personal':
      return ContactType.PERSONAL;
    case 'potential_customer':
      return ContactType.POTENTIAL_CUSTOMER;
    case 'unknown':
    default:
      return ContactType.UNCATEGORIZED;
  }
}
