import LinkedInWebview from '@common/Webview.renderer/Base/LinkedInWebview';
import { language } from '@/index';
import { Box, CircularProgress, Typography } from '@mui/material';

export default function LinkedInLogout() {
  return (
    <Box position="relative" height="100%">
      <Box
        sx={{
          pointerEvents: 'none',
          height: '100%',
          width: '100%'
        }}>
        <LinkedInWebview
          hideHomeButton
          ref={(ref) => {
            if (ref)
              ref.addEventListener('dom-ready', () =>
                ref?.send('linkedin:logout', undefined)
              );
          }}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          background: (theme) => theme.palette.background.default,
          inset: 0,
          top: 0,
          left: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          flexDirection: 'column'
        }}>
        <CircularProgress />
        <Typography
          variant="h2"
          sx={{
            'mt': 2,
            '&::after': {
              'content': "''",
              'display': 'inline-block',
              'minWidth': (theme) => theme.spacing(3),
              'animation': 'loading 2s linear infinite',
              'textAlign': 'left',
              '@keyframes loading': {
                '0%': {
                  content: "''"
                },
                '25%': {
                  content: "'.'"
                },
                '50%': {
                  content: "'..'"
                },
                '75%': {
                  content: "'...'"
                },
                '100%': {
                  content: "''"
                }
              }
            }
          }}>
          {language.text.logging_out}
        </Typography>
      </Box>
    </Box>
  );
}
