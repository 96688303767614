import React from 'react';
import RouteButton from './RouteButton';
import { Box, BoxProps, List } from '@mui/material';
import AccountBadge from './AccountBadge';
import { PriorityHigh, Update } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Ava from '@/components/Ava/Ava';
import { language } from '@/index';
import { APP_PATHS, LOADER_PATHS, MANUAL_MODE_PATHS } from '@/globals/Paths';
import { AvaIcon, Logo, useHover, useRefreshed } from 'ui-utils';
import { LinkedInWebviewElement } from '@common/Webview.renderer/Base/ContentScriptTypes';
import PostLoaderHelper from '@/Routes/Loader/PostLoader/PostLoaderHelper';
import { getManualModeRoutes } from '../ManualModeRouter';
import { useFeatureFlags } from '@/globals/featureFlags';
import { Styles } from '@/other/UtilTypes';

const ROOT_ROUTE = `/${APP_PATHS.Manual}`;
// Legacy local storage key
// const LOCAL_STORAGE_KEY = 'manualAppbarCollapsed';

const styles = {
  wrapper: {
    'maxWidth': (theme) => theme.spacing(9.625),
    'minWidth': (theme) => theme.spacing(9.625),
    'overflow': 'overlay',
    'height': '100%',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  collapsedWrapper: {
    'height': '100%',
    'zIndex': 10,
    'transition': (theme) =>
      theme.transitions.create(['background', 'box-shadow']),
    '&:hover': {
      position: 'absolute',
      background: (theme) => theme.palette.background.default,
      boxShadow: (theme) => theme.shadows[4]
    }
  }
} satisfies Styles;

export default function Appbar(props: BoxProps) {
  const [ref, hover] = useHover();
  const [closed, setClosed] = React.useState(false);
  React.useEffect(() => {
    if (!closed) return;
    const timeout = setTimeout(() => {
      setClosed(false);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [closed]);

  const isOpen = !closed && hover;
  // const [isOpen, setIsOpen] = React.useState(false);
  // window.dev.open = setIsOpen;

  return (
    <Box
      {...props}
      ref={ref}
      sx={{
        ...styles.wrapper,
        ...props.sx
      }}>
      <Box sx={styles.collapsedWrapper}>
        <AppbarInner collapsed={!isOpen} onClick={() => setClosed(true)} />
      </Box>
    </Box>
  );
}

export interface AppbarInnerProps {
  collapsed: boolean;
  onClick?: () => void;
}

const innerStyles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  logo: {
    height: (theme) => theme.spacing(6),
    width: (theme) => theme.spacing(6),
    cursor: 'pointer',
    zIndex: 12,
    position: 'sticky',
    top: (theme) => theme.spacing(1),
    mt: (theme) => theme.spacing(0.5),
    mb: 1.5
  }
} satisfies Styles;

function AppbarInner(props: AppbarInnerProps) {
  const navigate = useNavigate();
  const featureFlags = useFeatureFlags();
  const ROUTES = React.useMemo(
    () => getManualModeRoutes(featureFlags),
    [featureFlags]
  );

  const avaRef = React.useRef<HTMLElement | undefined>();

  const showPostLoader = useRefreshed(
    PostLoaderHelper.isDue.bind(PostLoaderHelper),
    30_000
  );

  return (
    <Box sx={innerStyles.wrapper}>
      <Logo
        variant={'blue'}
        sx={innerStyles.logo}
        onClick={() => navigate(ROOT_ROUTE)}
      />
      <AccountBadge onlyAvatar={props.collapsed} />
      <List
        sx={{
          flexGrow: 1
        }}>
        {ROUTES.map((route) =>
          route.hidden ? null : (
            <RouteButton
              key={route.path}
              icon={<route.icon />}
              label={route.name}
              link={route.path}
              onClick={
                route.path === MANUAL_MODE_PATHS.LinkedIn
                  ? (active) => {
                      props.onClick?.();
                      if (!active) {
                        return true;
                      }
                      // go to root of webview
                      const webview =
                        document.querySelector<LinkedInWebviewElement>(
                          'webview'
                        );
                      // src changes when navigating to a new page
                      // data-src is set by us as the original src
                      const src = webview?.getAttribute('data-src');
                      if (src) {
                        webview?.loadURL(src);
                      }
                      return false;
                    }
                  : () => {
                      props.onClick?.();
                      return true;
                    }
              }
              onlyIcon={props.collapsed}
              rootPath={ROOT_ROUTE}
            />
          )
        )}
        {featureFlags.advanced_load_enabled && showPostLoader && (
          <RouteButton
            icon={
              !props.collapsed ? (
                <PriorityHigh color="error" />
              ) : (
                <Update color={'error'} />
              )
            }
            label={language.text.load_posts}
            link={`/${APP_PATHS.Loader}/${LOADER_PATHS.Post}`}
            onlyIcon={props.collapsed}
            onClick={() => {
              props.onClick?.();
              return true;
            }}
          />
        )}
      </List>

      {featureFlags.show_ava && (
        <>
          {props.collapsed && (
            <Box
              onClick={() => {
                avaRef.current?.click();
              }}
              sx={{
                cursor: 'pointer',
                width: (theme) => theme.spacing(7.25),
                height: (theme) => theme.spacing(7.25),
                px: 1
              }}>
              <AvaIcon variant="square" width="100%" />
            </Box>
          )}
          <Ava avaRef={avaRef} hide={props.collapsed}>
            {language.text.ava_hint_manual}
          </Ava>
        </>
      )}
    </Box>
  );
}
