import { OnboardingSteps } from '@common/types/Setup';

const MainConfig = {
  releaseChannel: 'stable' as 'stable' | 'preview',
  mainWindowDevTools: false,
  userAgent: {
    version: 'Mozilla/5.0',
    platform: {
      win: 'Windows NT 10.0; Win64; x64',
      mac: 'Macintosh; Intel Mac OS X 10_15_7'
    },
    chrome:
      'AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36'
  },
  linkedInUrl: 'https://www.linkedin.com/',
  linkedInLoginUrl: 'https://www.linkedin.com/login/de',
  registerURL: 'https://social-selling-cockpit.com/',
  applyAsAffiliateLink: 'https://social-selling-cockpit.com/affiliate/',
  subscriptionManagementLink:
    'https://billing.stripe.com/p/login/28o28eb4geg26SA7ss',
  loginScreenURLs: {
    terminal: 'https://social-selling-cockpit.com/terminal',
    update: 'https://social-selling-cockpit.com/update',
    faqCall:
      'https://rwth.zoom.us/j/6481974231?pwd=RUZFbUZEeWFrSzMrZFBUQVFoUVlwUT09&utm_source=newsletter&utm_medium=email&utm_term=2023-10-06&utm_campaign=Du+wei+t+um+was+es+geht+#success'
  },
  profileSwitcherBaseURL: 'https://profile-switcher.idot-assets.de/',
  macosUpdaterBaseURl: 'https://macos-updater.idot-assets.de/',
  messageBusUrl: 'wss://api.social-selling-cockpit.co/ws/messagebus',
  videos: {
    login: {
      // This is when not logged in
      0: '_8kZkY5-sz0',
      2: '6CwS5WZPyhc',
      4: 'hz-9ym24rXo',
      5: 'MZyefpbZeq8',
      6: 'ZXlFePvRqL0',
      7: 'Yx4C6HcQdpA',
      8: '9lbveaqPjKA',
      9: 'EZm7OAMcXOA',
      10: 'MnN8uU7Dssk'
    } as { [key: number]: string },
    onboarding: {
      'manual:open': {
        title: 'Fokus Modus',
        videoID: 'oVNjcCVd7Nk'
      },
      'focus:audienceHolder': {
        title: 'Zielgruppenhalter',
        videoID: 'A3M2wEhZ08o'
      },
      'focus:categorization': {
        title: 'Kategorisieren',
        videoID: '2Dv-ii9V5qQ'
      },
      // ! unused
      'focus:categorization-10': {
        title: 'Kategorisieren - 10 Kontakte',
        videoID: 'USFSVe-juRQ'
      },
      'focus:expandNetwork': {
        title: 'Vormerken erklärt',
        videoID: 'S6i7Z_5_qzg'
      },
      // ! unused
      'focus:expandNetwork-15': {
        title: 'Vormerken - 15 Stück geschafft',
        videoID: 'jw2NBNNUSoc'
      },
      // ! unused
      'focus:expandNetwork-30': {
        title: 'Vormerken - 30 Stück geschafft',
        videoID: 'ql7G2wQ9tT4'
      },
      'focus:chat': null,
      'focus:comments': {
        title: "Kommentare schreiben - so geht's",
        videoID: '18AvMt8k4Po'
      },
      'focus:connectProfile': null,
      'focus:establishRelation': null,
      'focus:question': null,
      'manual:chatCockpit': {
        title: 'Chat Cockpit',
        videoID: 'K9lz6G-Y5FU'
      },
      'manual:dashboard': {
        title: 'Dashboard',
        videoID: 'iYj_rfSaVrw'
      },
      'manual:templates': {
        title: 'Vorlagen erstellen',
        videoID: '6YL6NtOuU6M'
      },
      'manual:training': {
        title: 'Piloten Training',
        videoID: 'wTWv68gIvjk'
      },
      // ! unused
      'misc:ava': {
        title: 'Ava: Deine persönliche Assistentin',
        videoID: 'ydTi89Ta2Ps'
      },
      'misc:welcome': {
        title: 'Willkommen im Social Selling Cockpit!',
        videoID: 'FBmBN8G2npE'
      }
    } as {
      [key in OnboardingSteps]: { videoID: string; title: string } | null;
    },
    explanation: [
      { title: 'Willkommen im SSC', videoID: '7r2omK6sN20' },
      { title: 'Kennenlernen Strategie', videoID: 'SV7k7mPAsxU' },
      { title: 'Zielgruppenhalter finden', videoID: 'AIXQ28EJRvE' },
      { title: 'Synchronisierung', videoID: '9VLP8jPlphA' }
    ],
    training: []
  },
  updater: {
    urls: {
      darwin: `https://updates.social-selling-cockpit.com/update/{{channel}}/{{arch}}/{{version}}/`,
      win32: `https://sscmain.blob.core.windows.net/binaries/{{channel}}/win32/`
    },
    checkForUpdateTimeout: 1000 * 60 * 30,
    pagesBasePath: 'src/main-thread/Updater/Pages'
  },
  setup: {
    urls: {
      usageSurvey: 'https://form.typeform.com/to/V93becHK'
    }
  },
  legal: {
    terms: 'https://social-selling-cockpit.com/terms/',
    privacy: 'https://social-selling-cockpit.com/privacy/'
  },
  logging: {
    axiom: {
      token: 'xaat-5275f2d0-b54a-4c9e-bb7e-07ea97648f7b',
      orgId: 'social-selling-cockpit-atlj'
    },
    posthog: {
      token: 'phc_QEFPmXmWCItuoAIJED8okFOgGTuvIaoX8gnqzzpuElx',
      apiHost: 'https://eu.i.posthog.com'
    },
    sentry: {
      dsn: 'https://7766030e4ca27e1013f13dbdffdd4598@o4506061669466112.ingest.sentry.io/4506061674381312',
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0
    }
  },
  localCredentialsFilePath: './credentials.json',
  /**
   * If a build should be run in dev mode, this flag can be set to true
   * This will use the dev server and activate features that are in dev mode
   * Can also be set to false to force a production build to run in dev mode
   */
  devModeOverride: null,
  /**
   * Amount of contacts that are loaded before the main cockpit is available
   */
  preloadContactsCount: 20,
  /**
   * On these days, the app will show a the maintenance page and will NOT be usable
   */
  maintenanceDays: [] as Date[],
  /**
   * last Date where subscription can be migrated
   */
  subscriptionMigrationDeadline: new Date('2023-11-30 23:59:59'),
  /**
   *
   */
  progression: {
    usageDaysToUnlockTraining: 7
  },
  /**
   * Default colors for contact tags
   */
  tagColors: [
    '#9F9ED4',
    '#7158F2',
    '#DB46D8',
    '#FF866B',
    '#FFC353',
    '#F9F871',
    '#F0EDFF',
    '#DCF6F4',
    '#464555',
    '#BFA975',
    '#EB0036',
    '#FF4565',
    '#4F8886',
    '#008A65',
    '#6AFBCF'
  ] as string[],
  conteaLink: 'https://get.contea.ai/de?c=XMC39DL',
  // URL where the files for the renderer are hosted
  rendererDeploymentURL: 'https://electron-ui.social-selling-cockpit.com',

  sscDownloadLink: 'https://social-selling-cockpit.com/terminal/',
  minimumVersion: '0.74.0', // minimum version of main thread part -> otherwise update required

  defaultStatGoals: {
    CommentPosted: 5,
    ConnectionRequestSend: 20,
    MessageSend: 20,
    ProfileCategorized: 10,
    ProfilesMarked: 50,
    UserActive: 90
  }
} as const;

export default MainConfig;
