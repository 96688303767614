import MainConfig from '@common/config/MainConfig';
import { language } from '@/index';
import { Loading } from '@/components/Loading/LoadingTypes';
import { navigate } from '@/Routes/MainRouter';
import { OnboardingSteps } from '@common/types/Setup';
import log from 'electron-log';
import SetupActions from '@/data/DataServer/Setup';
import { IconProps } from '@mui/material';
import { checkFeatureFlag } from '@/globals/featureFlags';

const getSetupSteps = (): readonly SetupStep[] => {
  try {
    return [
      ...(checkFeatureFlag('show_ava')
        ? ([
            {
              id: 'ava-tutorial',
              handler: 'ava-tutorial',
              header: ''
            }
          ] as const)
        : []),
      {
        id: 'accept_term_privacy',
        handler: 'term',
        header: language.text.terms_of_use,
        terms: [
          {
            name: language.text.i_accept_tos,
            url: MainConfig.legal.terms
          },
          {
            name: language.text.i_accept_data_privacy,
            url: MainConfig.legal.privacy
          }
        ] satisfies readonly Term[],
        hint: {
          text: language.text.you_cant_use_without_accepting_tos
        }
      },
      {
        id: 'confirmations',
        handler: 'confirmations',
        header: ''
      },
      ...(MainConfig.videos.onboarding['misc:welcome']
        ? ([
            {
              id: 'onboarding_misc:welcome',
              handler: 'video',
              header: MainConfig.videos.onboarding['misc:welcome'].title,
              skipable: true,
              rewatchable: false,
              video: MainConfig.videos.onboarding['misc:welcome'].videoID,
              extraButtons: [
                {
                  text: language.text.skip_all_tutorials,
                  onClick: SetupActions.completeAllOnboardingSteps
                }
              ]
            }
          ] as const)
        : []),
      {
        id: 'usage_survey',
        handler: 'url',
        header: language.text.tell_us_about_yourself,
        url: MainConfig.setup.urls.usageSurvey
      },
      {
        handler: 'linkedin-loading',
        id: 'load-linkedin-data',
        header: ''
      }
    ];
  } catch (e: unknown) {
    log.error('Could not load setup steps', e);
    if (typeof e === 'object' && e instanceof Error)
      navigate(`/error/${e.message}`);
    else navigate(`/error/setup-unknown`);
    return [];
  }
};

export type SetupStepID =
  | 'accept_term_privacy'
  | 'usage_survey'
  | 'loading'
  | 'load-linkedin-data'
  | 'ava-tutorial'
  | 'confirmations'
  | `onboarding_${OnboardingSteps}`
  | 'linkedin-loading-manager-setup'
  | 'skip_summary';

export type SetupStepHandler = SetupStep['handler'];

export type SetupStep =
  | SetupURLStep
  | SetupVideoStep
  | SetupTermStep
  | SetupLoadingStep
  | SetupLinkedInLoadingStep
  | SetupAvaTurorialStep
  | SetupConfirmationsStep;

export interface SetupConfirmationsStep extends BaseStep {
  handler: 'confirmations';
}

export interface SetupAvaTurorialStep extends BaseStep {
  handler: 'ava-tutorial';
}

export interface SetupLoadingStep extends BaseStep {
  handler: 'loading';
}

export interface SetupLinkedInLoadingStep extends BaseStep {
  handler: 'linkedin-loading';
}

export interface SetupTermStep extends BaseStep {
  handler: 'term';
  terms: Term[];
}

export interface Term {
  /**
   * surround words that should be clickable to open a link with a `%%`, example: `"Please accept the %%terms%%."`
   */
  name: string;
  url: string;
}

export interface SetupURLStep extends BaseStep {
  handler: 'url';
  url: string;
}

export interface SetupVideoStep extends BaseStep {
  handler: 'video';
  video: string;
  skipable: boolean;
  rewatchable: boolean;
  extraButtons?: readonly {
    text: string;
    onClick: () => Promise<void> | void;
    icon?: React.FC<IconProps>;
  }[];
}

interface BaseStep {
  id: SetupStepID;
  header: string;
  subtitle?: string;
  hint?: Hint;
  loadBefore?: boolean | (() => Promise<void>);
  loadAfter?: boolean | (() => Promise<void>);
  loadingType?: Exclude<Loading, Loading.NONE>;
  loadTimeout?: number;
}

export interface Hint {
  text?: string;
  video?: string;
}

export default getSetupSteps;
