import { language } from '@/index';
import { StatTypes } from '@common/types/ApiTypes';
import { TAssertIsNever } from '@idot-digital/generic-helpers';
import {
  AccessTime,
  Bookmark,
  Category,
  Chat,
  Link
} from '@mui/icons-material';
import { IconProps } from '@mui/material';
import { CommentIcon } from 'ui-utils';

export interface StatsAsset {
  title: string;
  description?: string;
  icon: React.FC<Pick<IconProps, 'sx'>>;
}

export interface Stat {
  type: StatTypes;
  current: number;
  avg: number;
  change: number;
}

export default class StatsHelper {
  static getStatsAsset(type: StatTypes): StatsAsset | null {
    switch (type) {
      case 'CommentPosted':
        return {
          title: language.text.comments_posted,
          icon: CommentIcon
        };
      case 'ConnectionRequestSend':
        return {
          title: language.text.connection_requests_sent,
          icon: Link
        };
      case 'MessageSend':
        return {
          title: language.text.messages_sent,
          icon: Chat
        };
      case 'ProfileCategorized':
        return {
          title: language.text.profiles_categorized,
          icon: Category
        };
      case 'ProfilesMarked':
        return {
          title: language.text.profiles_marked,
          icon: Bookmark
        };
      case 'UserActive':
        return {
          title: language.text.usage_time,
          icon: AccessTime
        };
      default:
        TAssertIsNever(type);
        return null;
    }
  }

  static getChange(value: number, previousValue?: number): number {
    if (previousValue === 0) return value;
    if (value === 0) return -1;

    const absoluteChange = value - (previousValue ?? 0);
    const relativeChange = absoluteChange / (previousValue ?? 1);

    return Math.round(relativeChange * 100) / 100;
  }

  static getStatTypes(): StatTypes[] {
    return [
      'CommentPosted',
      'ConnectionRequestSend',
      'MessageSend',
      'ProfileCategorized',
      'ProfilesMarked',
      'UserActive'
    ];
  }
}
